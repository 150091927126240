import ParkingNotice from "./components/ParkingNotice";
import './App.css';

function App() {
  return (
    <div className="App">

      <ParkingNotice />

    </div>
  );
}

export default App;
